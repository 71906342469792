html body{
    background-image: url('../images/vertical-wood.png');
}

.nav-link{
    color: rgba(24, 76, 173, 0.9) !important;
    font-weight: bold;
    font-family: 'Lato', sans-serif;
}

#navbar{
    margin-bottom: 20px;
    background-color: rgb(255,255,255,0.5);
}

.center{
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

.marginT-XXL{
    margin-top: 100px;
}

.marginT-L{
    margin-top: 50px;
}

img.full-width{
    width: 100%;
}

.page-wrapper{
    width: 90%;
    height: 100%;
    margin-left: 5%;
}

.nav-link:hover{
    opacity: 0.5;
}

hr.main-divider{
    width: 50%;
    border-top: 1px solid #004085;
}

.lodgeimages{
    width: 100%;
}

.roomBlockImage{
    width: 600px;
}


.header{
    font-family: 'Cinzel', serif;
    background-image: url('../images/dark-paper.jpg');
    height: 55px;
    padding-top: 10px;
    padding-left: 23px;
    color: white;
    border-radius: 5px;
    margin-top: 20px;
}

.header.main-title{
    width: 400px;
}

.geneva-text{
    background-image: url('../images/dark-paper.jpg');
    color: white;
    width: 635px;
    padding: 10px;
    padding-left: 28px;
    border-radius: 20px;
}

#activitiesPageContentDiv1, #activitiesPageContentDiv2 {
    background-image: url('../images/dark-paper.jpg');
    color: white;
    padding: 10px;
    padding-left: 28px;
    border-radius: 20px;
    width: 500px;
}

.header.important-dates{
    width: 335px;
    margin-top: 100px;
}

.important-dates-text{
    background-image: url('../images/dark-paper.jpg');
    color: white;
    width: 635px;
    height: 110px;
    border-radius: 20px;
    padding: 10px;
    padding-left: 28px;
    padding-top: 30px;
    margin-top: 30px;
}

.header.instructions{
    width: 840px;
    margin-top: 100px;
}
.instructions-text{
    background-image: url('../images/dark-paper.jpg');
    color: white;
    padding: 30px 10px 10px 28px;
    border-radius: 20px;
    margin-top: 30px;
    width: 840px;
}

.room-info-text{
    background-image: url('../images/dark-paper.jpg');
    color: white;
    width: 950px;
    height: 110px;
    border-radius: 20px;
    padding: 30px 10px 10px 28px;
    margin-top: 30px;
}

@media(max-width: 567px){
    .main-title{
        font-size: 16px;
        font-weight: bold;
    }
}

.center{
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
}

.display-flex{
    display: flex;
}

.flex-justify-content-center{
    justify-content: center;
}

.text-align-center{
    text-align: center;
}

.flex-direction-column{
    flex-direction: column;
}

#paypalSubmitButton{
    width: 300px;
}

#paymentPageContentDiv{
    margin-top: 110px;
}

/****One offs****/

.italicize{font-style: italic;}
.underline{text-decoration: underline;}
